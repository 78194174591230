import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import { TablePagination } from "@mui/material";
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#245AB1",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CheckingTable({
  data = [],
  loading,
  onClickDetailHandler,
  findProcessStateNameByStateId,
  rowsPerPageOptions = [5, 10, 25],
  defaultRowsPerPage = 5,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const { t } = useTranslation();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data?.length - page * rowsPerPage);

  return (
    <>
      <TableContainer component={Paper} className="mt-5">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="start">{t("No")}</StyledTableCell>
              <StyledTableCell align="center">{t('Request No')}</StyledTableCell>
              <StyledTableCell align="center">{t('Request Type')}</StyledTableCell>
              <StyledTableCell align="center">{t('Submitted Date')}</StyledTableCell>
              <StyledTableCell align="center">{t('Status')}</StyledTableCell>
              {/* <StyledTableCell align="center">{t('Detail')}</StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? data?.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : data
            )?.map((item, index) => (
              <StyledTableRow key={item.processid}>
                <StyledTableCell component="th" scope="row" align="start">
                  {index + 1 + page * rowsPerPage}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {item.processid}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {item.processname}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {item.createdt
                    ? dayjs(item.createdt).format("DD-MM-YYYY")
                    : "-"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {findProcessStateNameByStateId(item.stateid)}
                </StyledTableCell>
                {/* <StyledTableCell align="center">
                  <VisibilityIcon
                    onClick={() => {
                      onClickDetailHandler(item.processid);
                    }}
                    htmlColor="#245AB1"
                    style={{ cursor: "pointer" }}
                  />
                </StyledTableCell> */}
              </StyledTableRow>
            ))}
            {emptyRows > 0 && (
              <StyledTableRow style={{ height: 53 * emptyRows }}>
                <StyledTableCell colSpan={6} />
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={data?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

// const statusStateObject = {
//   pending: {
//     icon: <PendingIcon htmlColor="#EF8A49" />,
//     text: "รออนุมัติ",
//     color: "text-[#EF8A49]",
//   },
//   success: {
//     icon: <CheckCircleIcon htmlColor="#0AC398" />,
//     text: "อนุมัติ",
//     color: "text-[#0AC398]",
//   },
//   failed: {
//     icon: <CancelIcon htmlColor="#FC3838" />,
//     text: "ไม่อนุมัติ",
//     color: "text-[#FC3838]",
//   },
// };

// const StatusState = ({ stateid }) => {
//   let icon = <CheckCircleIcon htmlColor="#0AC398" />;
//   let text = "อนุมัติ";

//   return (
//     <div className="flex items-center justify-center gap-2 ">
//       {icon}
//       <span className="text-[#0AC398] font-semibold">{text}</span>
//     </div>
//   );
// };
