import React from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import Switch from "@mui/material/Switch";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import HeaderMenu from "../../layouts/HeaderMenu";
// import CheckBoxAOT from "../Controls/CheckBoxAOT";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { AIRPORT_ID, MEMBER_STATUS } from "../../constants";
import { useQuery } from "@tanstack/react-query";
import MemberService from "../../services/member-service";
import Container from "../../layouts/Container";

// const MyProfileHeader = styled(Box)(() => ({
//   display: "flex",
//   justifyContent: "space-between",
//   alignItems: "center",
//   padding: "3.991rem 6.25rem 0 6.25rem",
// }));

// const BoxDataMyProfile = styled(Box)(({ theme }) => ({
//   padding: "3.509rem 13.604rem 0 13.604rem",
//   display: "flex",
//   width: "100%",
//   [theme.breakpoints.down("lg")]: {
//     flexWrap: "wrap",
//   },
//   [theme.breakpoints.down("md")]: {
//     padding: "3.509rem 8rem 0 8rem",
//   },
// }));

// const BoxDataMyProfileRight = styled(Box)(({ theme }) => ({
//   paddingRight: "4.104rem",
//   display: "flex",
//   justifyContent: "flex-end",
//   flexDirection: "column",
//   [theme.breakpoints.down("lg")]: {
//     justifyContent: "center",
//   },
// }));

// const BoxDetailUser = styled(Box)(({ theme }) => ({
//   padding: "1rem 13.604rem 0 13.604rem",
//   [theme.breakpoints.down("lg")]: {
//     padding: "1rem 7rem 0 7rem",
//   },
// }));

// const BoxOuter = styled(Box)(({ theme }) => ({
//   padding: "1.67rem 13.604rem 0 13.604rem",
//   marginTop: "1rem",
//   [theme.breakpoints.down("lg")]: {
//     padding: "1.67rem 7rem 0 7rem",
//   },
// }));

// const BoxOuterLang = styled(Box)(({ theme }) => ({
//   padding: "1.67rem 13.604rem 0 13.604rem",
//   marginTop: "1rem",
//   [theme.breakpoints.down("lg")]: {
//     padding: "1.67rem 7rem 0 7rem",
//   },
// }));

// const BoxPw = styled(Box)(({ theme }) => ({
//   width: "100%",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "flex-end",
//   [theme.breakpoints.down("lg")]: {
//     width: "80%",
//   },
//   [theme.breakpoints.down("md")]: {
//     flexDirection: "column",
//     justifyContent: "start",
//   },
// }));

// const BoxAdd = styled(Box)(({ theme }) => ({
//   width: "100%",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "flex-end",
//   [theme.breakpoints.down("lg")]: {
//     width: "unset",
//   },
// }));

const BoxData = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "0.7rem",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const BoxInside = styled(Box)(() => ({
  border: "1px solid #B3BBC2",
  width: "100%",
  padding: "1rem 4.104rem 1rem 4.104rem",
  opacity: 1,
}));

const BoxSubject = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  borderBottom: "1px solid #B3BBC2",
  justifyContent: "space-between",
}));

// const BoxSubjectNotified = styled(Box)(() => ({
//   display: "flex",
//   justifyContent: "space-between",
//   alignItems: "center",
//   borderBottom: "1px solid #B3BBC2",
// }));

// const BoxSubject2 = styled(Box)(() => ({
//   display: "flex",
//   width: "65%",
// }));

// const BoxSubjectPassword = styled(Box)(() => ({
//   display: "flex",
//   position: "relative",
//   alignItems: "center",
//   textAlign: "left",
//   width: "100%",
//   marginTop: "0.5rem",
// }));

// const BoxSubjectAddress = styled(Box)(() => ({
//   display: "flex",
//   position: "relative",
//   alignItems: "center",
//   width: "100%",
//   borderBottom: "1px solid #B3BBC2",
//   paddingTop: "1rem",
//   paddingBottom: "1rem",
// }));

// const BoxAvartarMenuImg = styled(Box)(() => ({
//   position: "relative",
//   marginBottom: "2rem",
//   textAlign: "end",
// }));

// const BoxCredit = styled(Box)(() => ({
//   display: "flex",
//   flexDirection: "column",
//   width: "100%",
//   textAlign: "left",
//   marginLeft: "1rem",
// }));

// const TextMyProfileHeader = styled(Typography)(() => ({
//   font: "normal normal 600 24px/54px Noto Sans Thai",
//   letterSpacing: "0px",
//   color: "#2F2E2F",
// }));

// const TextRight = styled(Typography)(() => ({
//   font: "normal normal normal 18px/27px Noto Sans Thai",
//   letterSpacing: "0px",
//   color: "#2F2E2F",
//   marginLeft: "1rem",
// }));

// const TextRightBlue = styled(Typography)(() => ({
//   font: "normal normal 600 18px/27px Noto Sans Thai",
//   letterSpacing: "0px",
//   color: "#3C5AAD",
//   marginLeft: "0.5rem",
// }));

// const TextBtnRenew = styled(Typography)(() => ({
//   font: "normal normal normal 18px/33px Noto Sans Thai",
//   letterSpacing: "0px",
//   color: "#FFFFFF",
//   marginLeft: "1.393rem",
// }));

const TextHeaderData = styled(Typography)(() => ({
  font: "normal normal 600 20px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  marginBottom: "10px",
}));

const TextDataLeft = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 18px/25px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  width: "47%",
  [theme.breakpoints.down("lg")]: {
    width: "54%",
  },
}));

const TextDataRight = styled(Typography)(() => ({
  font: "normal normal normal 18px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
}));

const TextDataVIP = styled(Typography)(() => ({
  font: "normal normal bold 20px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#245AB1",
}));

const TextTitle = styled(Typography)(() => ({
  font: "normal normal 600 20px/47px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
}));

// const TextSetting = styled(Typography)(() => ({
//   font: "normal normal 600 22px/73px Noto Sans Thai",
//   letterSpacing: "0px",
//   color: "#2F2E2F",
// }));

const NormalText = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 18px/65px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  width: "27%",
  [theme.breakpoints.down("lg")]: {
    width: "37%",
  },
}));

const GreyText = styled(Typography)(() => ({
  font: "normal normal normal 16px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#898A8F",
  textAlign: "left",
  width: "65%",
}));

const GreyTextPw = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 22px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#898A8F",
  textAlign: "left",
  width: "65%",
  [theme.breakpoints.down("lg")]: {
    width: "43%",
    marginTop: "1rem",
  },
}));

// const GreyTextAdd = styled(Typography)(({ theme }) => ({
//   font: "normal normal normal 22px/33px Noto Sans Thai",
//   letterSpacing: "0px",
//   color: "#898A8F",
//   textAlign: "left",
//   width: "75%",
//   [theme.breakpoints.down("lg")]: {
//     width: "unset",
//     marginTop: "1rem",
//   },
// }));

// const GreyText2 = styled(Typography)(() => ({
//   font: "normal normal normal 18px/33px Noto Sans Thai",
//   letterSpacing: "0px",
//   color: "#898A8F",
//   textAlign: "left",
//   marginRight: "1.375rem",
//   // width: '65%'
// }));

// const LightGreyText = styled(Typography)(() => ({
//   font: "normal normal normal 14px/27px Noto Sans Thai",
//   letterSpacing: "0px",
//   color: "#B1BCC4",
//   // width: '8%'
//   // textAlign: 'end'
// }));

// const TextCredit = styled(Typography)(() => ({
//   font: "normal normal normal 22px/33px Noto Sans Thai",
//   letterSpacing: "0px",
//   color: "#2F2E2F",
// }));

// const NormalTextNotified = styled(Typography)(() => ({
//   font: "normal normal normal 22px/65px Noto Sans Thai",
//   letterSpacing: "0px",
//   color: "#2F2E2F",
// }));

// const ImgMyprofile = styled((props) => <Box component="img" {...props} />)(
//   ({ theme }) => ({
//     width: "2rem",
//     right: "15px",
//     marginRight: "1.5rem",
//     [theme.breakpoints.down("lg")]: {
//       //display: 'none'
//     },
//   })
// );

// const ImgBtnRenew = styled((props) => <Box component="img" {...props} />)(
//   () => ({
//     marginLeft: "1rem",
//   })
// );

// const ImgIconEdit = styled((props) => <Box component="img" {...props} />)(
//   () => ({
//     right: "5px",
//     marginLeft: "1.25rem",
//   })
// );

// const ImgPlus = styled(AddCircleIcon)(() => ({
//   width: "40px",
//   hight: "40px",
// }));

// const ImgAvartarMenu = styled((props) => <Box component="img" {...props} />)(
//   () => ({
//     right: "0",
//   })
// );

// const ImgAvartarMenuCamera = styled((props) => (
//   <Box component="img" {...props} />
// ))(() => ({
//   position: "absolute",
//   bottom: "0",
//   right: "0",
//   cursor: "pointer",
// }));

// const ImgIconLang = styled((props) => <Box component="img" {...props} />)(
//   () => ({
//     width: "40px",
//     height: "26px",
//     margin: "0 1rem 0 3.048rem",
//   })
// );

// const ImgTruth = styled((props) => <Box component="img" {...props} />)(() => ({
//   width: "28px",
//   hight: "34px",
// }));

// const IconCredit = styled((props) => <Box component="img" {...props} />)(
//   () => ({
//     height: "10px",
//     width: "34px",
//     paddingRight: "0.5rem",
//   })
// );

// const ButtonChangPass = styled(Button)(({ theme }) => ({
//   width: "274px",
//   height: "69px",
//   border: "1px solid #B3BBC2",
//   borderRadius: "4px",
//   background:
//     "transparent linear-gradient(91deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box",
//   [theme.breakpoints.down("lg")]: {
//     marginTop: "0.8rem",
//   },
// }));

// const ButtonRenew = styled(Button)(({ theme }) => ({
//   width: "200px",
//   height: "50px",
//   border: "1px solid #B3BBC2",
//   borderRadius: "4px",
//   background:
//     "transparent linear-gradient(91deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box",
//   [theme.breakpoints.down("lg")]: {
//     transform: "translate(73px, 0)",
//   },
// }));

// const ButtonCredit = styled(Button)(() => ({
//   width: "387px",
//   height: "69px",
//   display: "flex",
//   marginTop: "0.5rem",
//   borderRadius: "4px",
//   background: "#E9F0F5 0% 0% no-repeat padding-box",
// }));

// const ButtonAddLang = styled(Button)(() => ({
//   width: "208px",
//   height: "69px",
//   display: "flex",
//   borderRadius: "4px",
//   background: "#E9F0F5 0% 0% no-repeat padding-box",
// }));

// const IOSSwitch = styled((props) => (
//   <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
// ))(({ theme }) => ({
//   width: 65,
//   height: 33,
//   padding: 0,
//   "& .MuiSwitch-switchBase": {
//     padding: 0,
//     margin: 2,
//     transitionDuration: "300ms",
//     "&.Mui-checked": {
//       transform: "translateX(35px)",
//       color: "#fff",
//       "& + .MuiSwitch-track": {
//         backgroundColor: theme.palette.mode === "dark" ? "#3D9AEE" : "#3D9AEE",
//         opacity: 1,
//         border: 0,
//       },
//       "&.Mui-disabled + .MuiSwitch-track": {
//         opacity: 0.5,
//       },
//     },
//     "&.Mui-focusVisible .MuiSwitch-thumb": {
//       color: "#3D9AEE",
//       border: "6px solid #fff",
//     },
//     "&.Mui-disabled .MuiSwitch-thumb": {
//       color:
//         theme.palette.mode === "light"
//           ? theme.palette.grey[100]
//           : theme.palette.grey[600],
//     },
//     "&.Mui-disabled + .MuiSwitch-track": {
//       opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
//     },
//   },
//   "& .MuiSwitch-thumb": {
//     boxSizing: "border-box",
//     transform: "translateY(5px) translateX(5px)",
//     width: 18,
//     height: 18,
//   },
//   "& .MuiSwitch-track": {
//     borderRadius: 50,
//     backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
//     opacity: 1,
//     transition: theme.transitions.create(["background-color"], {
//       duration: 500,
//     }),
//   },
// }));

const MyProfile = () => {
  const { t } = useTranslation();
  // const navigate = useNavigate();

  const { profile, currentPmls } = useSelector(
    (state) => state.auth
  );
  const { pmlsId } = useSelector((state) => state.airport);

  const queryMMapMemberInfo = useQuery({
    queryKey: ["GET_MMAP_MEMBER_INFO", pmlsId],
    queryFn: () =>
    MemberService.getMMapMemberInfo({
        pmlsid: pmlsId,
        memberid: currentPmls?.pmlsmemberid,
      }),
    enabled: currentPmls?.pmlsmemberid !== undefined && pmlsId !== undefined,
    select: (data) => data.data.tbMember,
  });

  const queryMMapMemberInfoData = queryMMapMemberInfo?.data;

  const activeVehicle =
    queryMMapMemberInfoData?.membervehicle &&
    queryMMapMemberInfoData?.membervehicle?.find(
      (item) => item.status == "Active"
    );

  const memberAirportText =
    currentPmls == undefined
      ? "-"
      : pmlsId === AIRPORT_ID.DONMUANG
      ? t("Don Mueang International Airport")
      : t("Suvarnabhumi airport");

  const memberAddressText = `${queryMMapMemberInfoData?.houseno} ${
    queryMMapMemberInfoData?.buidling || ""
  } ${queryMMapMemberInfoData?.bypath} ${
    queryMMapMemberInfoData?.subdistrictname
  } ${queryMMapMemberInfoData?.districtname} ${
    queryMMapMemberInfoData?.provincename
  } ${queryMMapMemberInfoData?.postcode}`;
  
  return (
    <>
      <Container>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-3">
            <img src="/images/myprofile/ico_mydata_bl.svg" alt="img-my-data" />
            <span className="text-lg md:text-3xl font-bold">
              {t("My Information")}
            </span>
          </div>
          <div className="flex items-center gap-x-2">
            <span>{t("Home")} / </span>
            <span className="font-bold text-blue-700">
              {t("My Information")}
            </span>
          </div>
        </div>
        <div className="px-8 py-10">
          <div>
            <TextHeaderData>
              {t("Hello")} {profile?.userMenu?.firstname}{" "}
              {profile?.userMenu?.lastname}
            </TextHeaderData>
            <BoxData>
              <TextDataLeft>{t("Membership Status")}</TextDataLeft>
              <TextDataVIP>
                {currentPmls?.status !== undefined ? currentPmls?.status : "-"}
              </TextDataVIP>
            </BoxData>
            <BoxData>
              <TextDataLeft>{t("Airport Member Card")}</TextDataLeft>
              <TextDataRight>{memberAirportText}</TextDataRight>
            </BoxData>
            <BoxData>
              <TextDataLeft>{t("Member Number")}</TextDataLeft>
              <TextDataRight>{currentPmls?.memberno || "-"}</TextDataRight>
            </BoxData>
            <BoxData>
              <TextDataLeft>{t("Member Card")}</TextDataLeft>
              <TextDataRight>{currentPmls?.membercardid || "-"}</TextDataRight>
            </BoxData>

            <BoxData>
              <TextDataLeft>{t("License Plate")}</TextDataLeft>
              <TextDataRight>
                {activeVehicle?.licenseplate || "-"}{" "}
                {activeVehicle?.licenseprovince || "-"}
              </TextDataRight>
            </BoxData>

            <BoxData>
              <TextDataLeft>{t("Member Start Date")}</TextDataLeft>
              <TextDataRight>
                {(queryMMapMemberInfoData?.memberpackage?.startdate &&
                  dayjs(
                    queryMMapMemberInfoData?.memberpackage?.startdate
                  ).format("DD/MM/YYYY", {
                    locale: "th",
                  })) ||
                  "-"}
              </TextDataRight>
            </BoxData>
            <BoxData>
              <TextDataLeft>{t("Member Expired Date")}</TextDataLeft>
              <TextDataRight>
                {(queryMMapMemberInfoData?.memberpackage?.enddate &&
                  dayjs(queryMMapMemberInfoData?.memberpackage?.enddate).format(
                    "DD/MM/YYYY",
                    {
                      locale: "th",
                    }
                  )) ||
                  "-"}
              </TextDataRight>
            </BoxData>
          </div>
          {/* <BoxDataMyProfileRight>
            <BoxAvartarMenuImg>
              <ImgAvartarMenu src="/images/myprofile/ico_user_big.svg" alt="" />
              <ImgAvartarMenuCamera
                src={
                  profile?.userMenu?.imageurl
                    ? profile?.userMenu?.imageurl
                    : "/images/myprofile/ico_camera.svg"
                }
                alt=""
                onClick={() => {
                  alert("ok");
                }}
              />
            </BoxAvartarMenuImg>
            {memberInfo?.status === MEMBER_STATUS.ACTIVE && (
              <ButtonRenew
                onClick={() => {
                  navigate("/members/renew");
                }}
              >
                <ImgBtnRenew src="/images/myprofile/ico_renew_wh.svg"></ImgBtnRenew>
                <TextBtnRenew>{t("Renew Membership")}</TextBtnRenew>
              </ButtonRenew>
            )}
          </BoxDataMyProfileRight> */}
        </div>
        <div className="w-full px-8 md:px-14">
          <TextTitle>{t("User Detail")}</TextTitle>
          <BoxInside>
            <BoxSubject>
              <NormalText>{t("Username")}</NormalText>
              <GreyText>{profile?.userMenu?.username}</GreyText>
            </BoxSubject>
            <BoxSubject sx={{ borderBottom: "none" }}>
              <NormalText>{t("Password")}</NormalText>
              <GreyTextPw>**********</GreyTextPw>
              {/* <BoxPw>
                <ButtonChangPass
                  onClick={() => {
                    navigate("/members/myprofile/changpassword");
                  }}
                >
                  <ImgBtnRenew src="/images/myprofile/ico_edit_wh.svg"></ImgBtnRenew>
                  <TextBtnRenew>เปลี่ยนรหัสผ่าน</TextBtnRenew>
                </ButtonChangPass>
              </BoxPw> */}
            </BoxSubject>
          </BoxInside>
        </div>
        <div className="w-full px-8 md:px-14 mt-10">
          <TextTitle>{t("User Information")}</TextTitle>
          <BoxInside>
            <BoxSubject>
              <NormalText>{t("Name Surname")}</NormalText>
              <GreyText>
                {currentPmls?.fname || "-"} {currentPmls?.lname}
              </GreyText>
            </BoxSubject>
            <BoxSubject>
              <NormalText>{t("Phone No")}</NormalText>
              <GreyText>{currentPmls?.mobile || "-"}</GreyText>
            </BoxSubject>
            <BoxSubject>
              <NormalText>{t("Email")}</NormalText>
              <GreyText>{currentPmls?.email || "-"}</GreyText>
            </BoxSubject>
            <BoxSubject>
              <NormalText>{t("Birthdate")}</NormalText>
              <GreyText>
                {currentPmls?.birthdate
                  ? dayjs(currentPmls?.birthdate).format("DD/MM/YYYY", {
                      locale: "th",
                    })
                  : "-"}
              </GreyText>
            </BoxSubject>
            <BoxSubject sx={{ borderBottom: "none" }}>
              <NormalText>{t("Address")}</NormalText>
              <GreyText>
                {(queryMMapMemberInfoData && memberAddressText) || "-"}
              </GreyText>
              {/* <BoxAdd>
                <GreyTextAdd>{memberAddressText}</GreyTextAdd>
                <ImgIconEdit src="/images/myprofile/ico_edit_bl.svg"></ImgIconEdit>
              </BoxAdd> */}
            </BoxSubject>
            {/* <BoxSubject>
              <NormalText>{t('License Plate')}</NormalText>
              {(queryMMapMemberInfoData?.membervehicle && (
                <BoxSubject2>
                  <GreyText2>
                    {
                      queryMMapMemberInfoData?.membervehicle?.find(
                        (item) => item.vehicletypeid == 1 && item.isprimary == 1
                      )?.licenseplate
                    }
                  </GreyText2>
                  <LightGreyText>({memberAirportText})</LightGreyText>
                </BoxSubject2>
              )) || <GreyText>-</GreyText>}
            </BoxSubject> */}
            {/* <BoxSubject sx={{ border: 'none' }}>
                            <NormalText>บัตรเติมเงิน Prepaid</NormalText>
                            <GreyText>มี</GreyText>
                        </BoxSubject> */}
          </BoxInside>
        </div>
        {/* <BoxOuter>
                    <TextTitle>บัตรเติมเงิน Prepaid</TextTitle>
                    <BoxInside>
                        <BoxSubject>
                            <NormalText>หมายเลข</NormalText>
                            <BoxSubject2>
                                <GreyText2>หมายเลข A 34567-68</GreyText2>
                                <LightGreyText>(ท่าอากาศยานสุวรรณภูมิ)</LightGreyText>
                            </BoxSubject2>
                        </BoxSubject>
                        <BoxSubject sx={{ border: 'none' }}>
                            <NormalText>วันเปิดใช้บัตร </NormalText>
                            <GreyText>3 มกราคม 2565</GreyText>
                        </BoxSubject>
                    </BoxInside>
                </BoxOuter> */}
        {/* <BoxOuter>
                    <TextTitle>บัตรเครดิตของฉัน</TextTitle>
                    <BoxInside>
                        <BoxSubject>
                            <NormalText>หมายเลข</NormalText>
                            <GreyText>**** **** **** 3457</GreyText>
                        </BoxSubject>
                        <ButtonCredit>
                            <ImgPlus sx={{ width: '20px' }} />
                            <BoxCredit>
                                <TextCredit>เพิ่มบัตรเครดิต / บัตรเดบิต</TextCredit>
                                <Box sx={{ display: 'flex' }}>
                                    <IconCredit src='/images/myprofile/ico_visa.svg' />
                                    <IconCredit src='/images/myprofile/ico_mastercard.svg' />
                                    <IconCredit src='/images/myprofile/ico_jcb.svg' />
                                </Box>
                            </BoxCredit>
                        </ButtonCredit>
                    </BoxInside>
                </BoxOuter> */}
        {/* <BoxOuter>
                    <TextTitle>ลักษณะการใช้งาน</TextTitle>
                    <BoxInside>
                        <Box
                            sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #B3BBC2' }}
                        >
                            <TextSetting>การตั้งค่าภาษา</TextSetting>
                            <ButtonAddLang>
                                <ImgPlus sx={{ marginRight: '0.5rem' }} />
                                <TextCredit>เพิ่มภาษา</TextCredit>
                            </ButtonAddLang>
                        </Box>
                        <BoxSubject>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <CheckBoxAOT></CheckBoxAOT>
                                <ImgIconLang src='/images/myprofile/ico_thaiflage.svg' />
                                <GreyText>ไทย</GreyText>
                            </Box>
                            <ImgTruth src='/images/myprofile/ico_trush_bl.svg' />
                        </BoxSubject>
                        <BoxSubject sx={{ border: 'none' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <CheckBoxAOT></CheckBoxAOT>
                                <ImgIconLang src='/images/myprofile/ico_engflage.svg' />
                                <GreyText>อังกฤษ</GreyText>
                            </Box>
                            <ImgTruth src='/images/myprofile/ico_trush_bl.svg' />
                        </BoxSubject>
                    </BoxInside>
                </BoxOuter> */}
        {/* <BoxOuter sx={{ marginBottom: '9.938rem' }}>
                    <BoxInside>
                        <TextTitle>แจ้งเตือน</TextTitle>
                        <BoxSubjectNotified>
                            <NormalTextNotified>แจ้งเตือนหมดอายุสมาชิก</NormalTextNotified>
                            <IOSSwitch defaultChecked />
                        </BoxSubjectNotified>
                        <BoxSubjectNotified>
                            <NormalTextNotified>แจ้งเตือนรถออกจากอาคารจอดรถยนต์</NormalTextNotified>
                            <IOSSwitch defaultChecked />
                        </BoxSubjectNotified>
                        <BoxSubjectNotified>
                            <NormalTextNotified>แจ้งเตือนชั่วโมงรวมที่จอดรถ</NormalTextNotified>
                            <IOSSwitch defaultChecked />
                        </BoxSubjectNotified>
                        <BoxSubjectNotified>
                            <NormalTextNotified>แจ้งเตือนค่าจอดรถปัจจุบันตามรอบระยะเวลาที่กำหนด</NormalTextNotified>
                            <IOSSwitch defaultChecked />
                        </BoxSubjectNotified>
                        <BoxSubjectNotified sx={{ border: 'none' }}>
                            <NormalTextNotified>แจ้งเตือนโปรโมชั่นใหม่</NormalTextNotified>
                            <IOSSwitch defaultChecked />
                        </BoxSubjectNotified>
                    </BoxInside>
                </BoxOuter> */}
      </Container>
    </>
  );
};

export default MyProfile;
