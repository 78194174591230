import React from "react";
import { useTranslation } from "react-i18next";

const ResponseChangeVehicle = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center mt-10  text-center gap-8">
      <img src="/images/register/img_complate.png" className="w-[200px]" alt="img-complete"/>
      <p className="text-[#3D9AEE] font-bold text-2xl">
        {t("Completed")}
      </p>
      <div className="text-[#2F2E2F] text-center">
        <p className="text-2xl">{t("submitted a change of car")}</p>
        <p className="text-base mt-4">
          {t("process after submitted a change of car")}
        </p>
      </div>
    </div>
  );
};

export default ResponseChangeVehicle;
