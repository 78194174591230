import React from 'react'
import { Box, Button, Grid, styled, Typography } from '@mui/material'
import InputTextFieldAOT from '../Controls/InputTextFieldAOT'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Loading from '../../components/Loading'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ChangPasswordHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '3.991rem 6.25rem 0 6.25rem',
    [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        padding: '3.991rem 3.25rem 0 3.25rem'
    }
}))

const BoxInput = styled(Box)(({ theme }) => ({
    padding: '5.902rem 13.604rem 7.375rem 13.604rem',
    [theme.breakpoints.down('lg')]: {
        padding: '5.902rem 10rem 7.375rem 10rem'
    },
    [theme.breakpoints.down('md')]: {
        padding: '3.902rem 7rem 7.375rem 7rem'
    }
}))

const BoxMenu = styled(Box)(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('lg')]: {
        // padding: '5.902rem 10rem 7.375rem 10rem'
    },
    [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        margin: '1rem 0'
    }
}))

const BoxMessErr = styled(Box)(() => ({
    color: 'red',
    marginRight: 'auto',
    paddingLeft: '1rem'
}))

const ImgKey = styled((props) => <Box component='img' {...props} />)(({ theme }) => ({
    width: '3.063rem',
    marginRight: '2.108rem',
    [theme.breakpoints.down('lg')]: {
        //display: 'none'
    }
}))

const TextChangPasswordHeader = styled(Typography)(() => ({
    font: 'normal normal 600 36px/54px Noto Sans Thai',
    letterSpacing: '0px',
    color: '#2F2E2F'
}))

const TextRight = styled(Typography)(() => ({
    font: 'normal normal normal 18px/27px Noto Sans Thai',
    letterSpacing: '0px',
    color: '#2F2E2F',
    marginLeft: '0.2rem'
}))

const TextRightBlue = styled(Typography)(() => ({
    font: 'normal normal 600 18px/27px Noto Sans Thai',
    letterSpacing: '0px',
    color: '#3C5AAD',
    marginLeft: '0.2rem'
}))

const TextLightGray = styled(Typography)(() => ({
    font: 'normal normal normal 18px/27px Noto Sans Thai',
    letterSpacing: '0px',
    color: '#898A8F',
    marginTop: '0.8rem',
    marginLeft: '1rem'
}))

const GridContainer = styled((props) => <Grid container spacing={2} {...props} />)(({ theme }) => ({
    marginBottom: '2.795rem',
    [theme.breakpoints.down('lg')]: {
        //display: 'none'
    }
}))

const GridItemFull = styled((props) => <Grid item xs={12} {...props} />)(({ theme }) => ({
    [theme.breakpoints.down('lg')]: {
        //display: 'none'
    }
}))

const ButtonSave = styled(Button)(({ theme }) => ({
    width: '100%',
    height: '5rem',
    font: 'normal normal 600 22px/33px Noto Sans Thai',
    letterSpacing: '0px',
    color: '#FFFFFF',
    background: 'transparent linear-gradient(90deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box',
    border: '2px solid #3D9AEE',
    borderRadius: '10px',
    '&.Mui-disabled': {
        color: '#FFFFFF',
        opacity: 0.5
    },
    [theme.breakpoints.down('lg')]: {
        width: '100%'
    }
}))

const validationSchema = Yup.object({
    passwordNew: Yup.string()
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .required('password is required'),
    password: Yup.string().required('password is required'),
    passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .required('passwordConfirmation is required')
})

const MyProfileChangPassword = () => {
    // const navigate = useNavigate()
    const { t } = useTranslation()
    // const [isLoading, setIsLoading] = useState(false)

    const formik = useFormik({
        initialValues: {
            passwordNew: '',
            password: '',
            passwordConfirmation: ''
        },
        onSubmit: async (values) => {
            // const data = {
            //     passwordNew: values.passwordNew,
            //     password: values.password
            // }
            // try {
            //     // setIsLoading(true)
            //     // const res = await registerService.registerUser(data)
            //     // setIsLoading(false)
            //     // if (res.ouIsComplete === 1) {
            //     //     alert('register success')
            //     //     navigate('/auth/user/login/')
            //     // } else {
            //     //     alert(res.ouMessage)
            //     // }
            // } catch (error) {
            //     console.log(error)
            //     setIsLoading(false)
            // }
        },
        validationSchema
    })

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <ChangPasswordHeader>
                    <Box sx={{ display: 'flex' }}>
                        <ImgKey src='/images/myprofile/ico_key_bl.svg' alt='' />
                        <TextChangPasswordHeader>เปลี่ยนรหัสผ่าน</TextChangPasswordHeader>
                    </Box>
                    <BoxMenu>
                        <TextRight>{t('Home')} /</TextRight>
                        <TextRight> {t('My Information')} /</TextRight>
                        <TextRightBlue>เปลี่ยนรหัสผ่าน</TextRightBlue>
                    </BoxMenu>
                </ChangPasswordHeader>
                <form onSubmit={formik.handleSubmit} autoComplete='off'>
                    <BoxInput>
                        <GridContainer>
                            <GridItemFull>
                                <InputTextFieldAOT
                                    label='ระบุรหัสผ่านปัจจุบัน *'
                                    name='passwordNew'
                                    sxa={{ height: '88px' }}
                                />
                            </GridItemFull>
                            <GridItemFull>
                                <InputTextFieldAOT
                                    label='ระบุรหัสผ่านใหม่ *'
                                    name='password'
                                    sxa={{ height: '88px' }}
                                />
                                <TextLightGray>อักขระอย่างน้อย8 ตัวอักษรประกอบด้วยตัวอักษรและตัวเลข</TextLightGray>
                                <BoxMessErr>
                                    {formik.errors.password && formik.touched ? formik.errors.password : null}
                                </BoxMessErr>
                            </GridItemFull>
                            <GridItemFull>
                                <InputTextFieldAOT
                                    label='ยืนยันรหัสผ่านใหม่ *'
                                    name='passwordConfirmation'
                                    sxa={{ height: '88px' }}
                                />
                                <BoxMessErr>
                                    {formik.errors.passwordConfirmation && formik.touched
                                        ? formik.errors.passwordConfirmation
                                        : null}
                                </BoxMessErr>
                            </GridItemFull>
                        </GridContainer>
                        <ButtonSave type='submit' disabled={!formik.isValid}>
                            บันทึก
                        </ButtonSave>
                    </BoxInput>
                </form>
                {/* {isLoading ? <Loading /> : null} */}
            </Box>
        </>
    )
}

export default MyProfileChangPassword
