import instance from "../config/apiAxios";

const BASE_PATH = "/subsystem/pmls/sys/parking";

const ParkingService = {
  remainSpace: (params) =>
    instance.get("/mapp/parking/pgs/remainspace", { params }),

  searchVehicle: (body) => instance.post("/subsystem/pgs/searchvehicle", body),

  getParkingAreaByParkingPMLS: (body) =>
    instance.post(BASE_PATH + "/area/listbyparking", body),

  //PMCS_TB_0092 : Get parking gate from pmls
  getParkingGateFromPMLS: async (params) => {
    return instance.get(BASE_PATH + "/gate", { params });
  },

  //PMCS_TB_0088 : Get all parking gate from pmls
  getParkingGateFormPMLSAll: async (params) => {
    return instance.get(BASE_PATH + "/gate/all", { params });
  },

  // PMCS_TB_0078 : Get all parking from pmls
  getParkingAll: async (pmlsid, langid) => {
    const response = await instance.get(BASE_PATH + `/all`, {
      params: {
        pmlsid,
        langid,
      },
    });
    return response.data;
  },
  getParkingGroupFormPMLS: async (params) => {
    return instance.get(BASE_PATH + "/group", {
        params
    })
  }
};

export default ParkingService;
