import React from "react";
import ModalClosePaymentRequestReceipt from "../../components/Modal/ModalClosePaymentRequestReceipt";


const CloseRequestReceiptPage = () => {

  return (
    <ModalClosePaymentRequestReceipt
      open={true}
      handleClose={() => {}}
      showCloseIcon={false}
    />
  );
};

export default CloseRequestReceiptPage;
