import React from "react";
import LicenseVehicleBlock from "./LicenseVehicleBlock";
import { convertToURI } from "../../utils/fileUpload";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { TitleWithValue } from "../Base/TitleWithValue";

const PreviewChangeVehicle = ({
  formikValues,
  primaryVehicle,
  memberInfo,
  getDoctypeName,
  handleRemoveUpload,
}) => {
  const { t } = useTranslation();

  const currentVehicleDate = {
    startDate: dayjs(memberInfo?.memberpackage?.startdate).format("DD/MM/YYYY"),
    endDate: dayjs(memberInfo?.memberpackage?.enddate).format("DD/MM/YYYY"),
  };

  return (
    <div className="w-full">
      <div className="flex flex-col items-center gap-y-3">
        <div className="max-w-4xl">
          <div className="flex px-7 gap-x-20 mb-10 ">
            <LicenseVehicleBlock
              name={t("Existing Car")}
              license={primaryVehicle?.licenseplate}
            />
            <div className="self-center">
              <img
                src="/images/change_vehicle/ico_switch.svg"
                className="w-[45px] auto"
                alt="icon-change-vehicle"
              />
            </div>
            <LicenseVehicleBlock
              name={t("New Car")}
              license={formikValues?.licenseplate}
            />
          </div>
          <div className="border border-[#B3BBC2] border-opacity-70 rounded-md py-10 px-5 w-full">
            <p className="font-semibold text-lg md:text-xl px-2">
              {t("Car Changing Information")}
            </p>
            <div className="px-6 py-2 divide-y">
              <TitleWithValue
                title={t("Existing License Plate")}
                boxStyles="py-4"
                value={primaryVehicle?.licenseplate}
              />
              <TitleWithValue
                title={t("Province")}
                boxStyles="py-4"
                value={primaryVehicle?.licenseprovince}
              />
              <TitleWithValue
                title={t("Car Usage Type")}
                boxStyles="py-4"
                value={primaryVehicle?.isprimary === 1 ? "ถาวร" : "ชั่วคราว"}
              />
              <TitleWithValue
                title={t("Apply Start Date")}
                boxStyles="py-4"
                value={currentVehicleDate.startDate || "-"}
              />
              <TitleWithValue
                title={t("End Date")}
                boxStyles="py-4"
                value={currentVehicleDate.endDate || "-"}
              />
              <TitleWithValue
                title={t("New License Plate")}
                boxStyles="py-4"
                value={formikValues?.licenseplate}
              />
              <TitleWithValue
                title={t("Car Usage Type")}
                boxStyles="py-4"
                value={formikValues?.ispermanent === 1 ? "ถาวร" : "ชั่วคราว"}
              />

              <TitleWithValue
                title={t("Apply Start Date")}
                boxStyles="py-4"
                value={
                  formikValues?.ispermanent === 1
                    ? currentVehicleDate.startDate
                    : formikValues?.startdate
                    ? dayjs(formikValues?.startdate).format("DD/MM/YYYY")
                    : "-"
                }
              />
              <TitleWithValue
                title={t("End Date")}
                boxStyles="py-4"
                value={
                  formikValues?.ispermanent === 1
                    ? currentVehicleDate.endDate
                    : formikValues?.enddate
                    ? dayjs(formikValues?.enddate).format("DD/MM/YYYY")
                    : "-"
                }
              />

              <TitleWithValue
                title={t("Province")}
                boxStyles="py-4"
                value={formikValues?.licenseprovince}
              />
              <TitleWithValue
                title={t("Brand")}
                boxStyles="py-4"
                value={formikValues?.brand_name}
              />
              <TitleWithValue
                title={t("License Plate Type")}
                boxStyles="py-4"
                value={formikValues?.licensetype_name || "-"}
              />
              <TitleWithValue
                title={t("Model")}
                boxStyles="py-4"
                value={
                  formikValues?.vehiclemodel ? formikValues?.vehiclemodel : "-"
                }
              />
              <TitleWithValue
                title={t("Vehicle Color")}
                boxStyles="py-4"
                value={
                  formikValues?.vehiclecolor ? formikValues?.vehiclecolor : "-"
                }
              />
              <TitleWithValue
                title={t("Vehicle Type")}
                boxStyles="py-4"
                value={formikValues?.vehicletype_name}
              />
              <TitleWithValue
                title={t("Owner Type")}
                boxStyles="py-4"
                value={formikValues?.vehicleowner_name}
              />
              {formikValues?.vehicleowner !== 1 && (
                <TitleWithValue
                  title={t("ID No in case you are not a vehicle owner")}
                  boxStyles="py-4"
                  value={formikValues?.vehicleowner_identity}
                />
              )}
            </div>

            <div className="border border-[#B3BBC2] border-opacity-60 rounded-sm py-4 px-6  mt-5">
              <p className="text-[#2F2E2F] font-semibold text-lg">
                {t("Uploaded Documents")}
              </p>
              <div className="px-6 py-7 gap-y-4 flex flex-row gap-x-5 flex-wrap justify-center">
                {formikValues?.doc.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="text-center  flex flex-col items-center gap-2 w-[250px] "
                    >
                      <div className="border border-neutral-200 w-full gap-2 flex items-center flex-col py-2 px-2 rounded-md">
                        <img
                          src={convertToURI(item.data_base64)}
                          className=" min-w-[120px]  h-36 rounded-sm object-contain"
                          alt="doc-img"
                        />

                        <span
                          onClick={() => handleRemoveUpload(index)}
                          className="text-red-500 cursor-pointer"
                        >
                          Remove
                        </span>
                      </div>
                      <span>{`${index + 1}. ${getDoctypeName(
                        item.doctypeid
                      )}`}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewChangeVehicle;
