import React from "react";

import { useTranslation } from "react-i18next";

const CloseQRTicketFeeParkingPage = () => {

  if (true) {
    return (
      <div className="w-full h-screen flex justify-center  items-center justify-items-center px-10">
        <img
          src="/images/carpark-banner-payment-not-ready.jpg"
          className="w-[500px] h-auto border "
          alt="carpark-banner-payment-not-ready"
        />
      </div>
    );
  }
};

export default CloseQRTicketFeeParkingPage;
