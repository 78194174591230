import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { TablePagination } from "@mui/material";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#245AB1",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function MyTicketTable({
  data,
  loading,
  rowsPerPageOptions = [5, 10, 25],
  defaultRowsPerPage = 5,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data?.length - page * rowsPerPage);

  const onClickDetailHandler = (id) => {
    navigate(`/members/myticket/${id}`);
  };

  const dataTable =
    rowsPerPage > 0
      ? data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : data;

  const ticketTypeMap = (ticketType) => {
    return ticketType === "reserve" ? "บัตรจองที่จอดรถ" : "บัตรจอดรถทั่วไป";
  };

  return (
    <>
      <TableContainer component={Paper} className="mt-5">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {/* translation */}
              {/* <StyledTableCell align="start">{t('Date')}</StyledTableCell>
            <StyledTableCell align="center">{t('Transaction Type')}</StyledTableCell>
            <StyledTableCell align="center">{t('Receipt No')}</StyledTableCell>
            <StyledTableCell align="center">{t('Status')}</StyledTableCell>
            <StyledTableCell align="center">{t('Status')}</StyledTableCell> */}

              <StyledTableCell align="start">ลำดับ</StyledTableCell>
              <StyledTableCell align="center">หมายเลขบัตรจอดรถ</StyledTableCell>
              <StyledTableCell align="center">ประเภทบัตรจอดรถ</StyledTableCell>
              <StyledTableCell align="center">{t("Status")}</StyledTableCell>
              <StyledTableCell align="center">รายละเอียด</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <StyledTableRow>
                <StyledTableCell align="center" colSpan={6}>
                  Loading...
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              dataTable?.map((item, index) => (
                <StyledTableRow key={item.processid}>
                  <StyledTableCell align="start">{index + 1 + page * rowsPerPage}</StyledTableCell>
                  <StyledTableCell align="center">
                    {item?.ticketno}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {ticketTypeMap(item?.tickettype)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item?.state ? item?.state : "-"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <VisibilityIcon
                      onClick={() => {
                        onClickDetailHandler(item.ticketno);
                      }}
                      htmlColor="#245AB1"
                      style={{ cursor: "pointer" }}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}

            {emptyRows > 0 && (
              <StyledTableRow style={{ height: 53 * emptyRows }}>
                <StyledTableCell colSpan={6} />
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={data?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
