import React, { useEffect, useMemo, useState } from "react";
import { InputTextFieldAOT } from "../components/Controls";
import {
  UilListUl,
  UilFileLandscapeAlt,
  UilMessage,
} from "@iconscout/react-unicons";
import ButtonAction from "../components/Base/ButtonAction";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import PaymentService from "../services/payment-service";
import { MoonLoader } from "react-spinners";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import ResultWithContent from "../components/Result/ResultWithContent";
import LanguagesDropdown from "../components/LanguagesDropdown";
import {
  getAirportNameFormPmlsAndLang,
  successRequest,
  toastErr,
} from "../utils";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { AIRPORT_ID, LANG } from "../constants";
import dayjs from "dayjs";

const STEP = {
  FORM: "form",
  DUE_TIME_OUT: "close-payment",
  SUCCESS: "success",
  CANCEL_REQUEST: "cancel_request",
  ALREADY_REQUEST: "already_request",
};

const RECEIPT_PAYMENT_STATUS = {
  FAILED: "0",
  SUCCESS: "1",
  CANCELED: "2",
};

const RequestReceiptPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { paymentType } = useSelector((select) => select.masterData);
  const location = useLocation();
  const [pmlsid, setPmlsId] = useState("");
  const [loading, setLoading] = useState(true);
  const [isWithDrawerValue, setIsWithDrawerValue] = useState("1");
  const [step, setStep] = useState(STEP.FORM);

  const isDmkAirport = pmlsid === String(AIRPORT_ID.DONMUANG);

  const getPaymentReceiptMutate = useMutation({
    mutationFn: (payload) => PaymentService.getPaymentReceipt(payload),
    onSuccess: (response) => {
      if (successRequest(response)) {
        const data = response?.data?.TbPaymentHdr || {};
        if (data?.status !== "1") {
          if (data?.recieptprocessid) {
            //"เลขที่ใบเสร็จนี้มีการขอออกใบกำกับภาษีเต็มรูปแบบแล้ว กรุณาติดต่อรับเอกสาร..."
            setStep(STEP.ALREADY_REQUEST);
          } else {
            //"เลขที่ใบเสร็จนี้นี้ถูกยกเลิกแล้ว"
            setStep(STEP.CANCEL_REQUEST);
          }
        } else if (data?.status === RECEIPT_PAYMENT_STATUS.SUCCESS) {
          const nextEndDateOfCreatedt = dayjs(data?.createdt)
            .add(1, "day")
            .endOf("day");
          const nowDate = dayjs();
          const isBeforeNextDate = nextEndDateOfCreatedt.isBefore(nowDate);
          //เลยเวลาที่ขอได้
          if (isBeforeNextDate) {
            setStep(STEP.DUE_TIME_OUT);
          } else {
            setStep(STEP.FORM);
          }
        }
      } else {
        toastErr(response?.ouMessage);
      }
      setLoading(false);
    },
    onError: (err) => {
      toastErr(err);
      setLoading(false);
    },
  });

  const createReceiptMutate = useMutation({
    mutationFn: (payload) => PaymentService.createReceiptProcessAtPmls(payload),
    onSuccess: (response) => {
      if (successRequest(response)) {
        setStep(STEP.SUCCESS);
      } else {
        if (response?.ouReasonCode === "100209") {
          setStep(STEP.DUE_TIME_OUT);
        } else {
          toastErr(response?.ouMessage);
        }
      }
    },
    onError: (err) => {
      alert(err);
    },
  });

  const paymentReceiptData =
    getPaymentReceiptMutate?.data?.data?.TbPaymentHdr || {};

  const paymentNameByPaymentCode = useMemo(() => {
    const find = paymentType?.find(
      (type) => type.paymentcode === paymentReceiptData?.paytype
    );

    if (find) {
      return i18n.language === LANG.TH ? find?.descth : find?.descen;
    } else {
      return "";
    }
  }, [paymentReceiptData, paymentType, i18n.language]);

  useEffect(() => {
    const fetchData = () => {
      const searchParams = new URLSearchParams(location.search);
      const _receiptNo = searchParams.get("rr");
      const _pmlsid = searchParams.get("pmlsid");

      if (!_receiptNo) {
        toastErr("Receipt No. not found!");
        return;
      }
      if (!_pmlsid) {
        toastErr("Pmlsid not founded!");
        return;
      }
      setPmlsId(_pmlsid);
      getPaymentReceiptMutate.mutate({
        pmlsid: _pmlsid,
        receiptno: _receiptNo,
      });
    };
    fetchData();
  }, [location]);

  const validationSchema = Yup.object().shape({
    taxno: Yup.string()
      .matches(/^[0-9]+$/, "Tax number must contain only numbers")
      .length(13, "Tax number must be exactly 13 characters")
      .required("Tax number is required"),

    officename:
      isWithDrawerValue === "1"
        ? Yup.string()
            .matches(
              /^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]*$/,
              "Office name must contain only English letters, numbers, spaces, and special characters"
            )
            .max(35, "Office name must be less than 35 characters")
            .required("Office name is required")
        : Yup.string()
            .matches(
              /^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]*$/,
              "Office name must contain only English letters, numbers, spaces, and special characters"
            )
            .max(35, "Office name must be less than 35 characters"),

    customername:
      isWithDrawerValue === "1"
        ? Yup.string()
        : Yup.string()
            .matches(
              /^[a-zA-Z\s]*$/,
              "Customer name must contain only English letters and spaces"
            )
            .max(35, "Customer name must be less than 35 characters")
            .required("Customer name is required"),
    branchname: Yup.string()
      .matches(/^[0-9]+$/, "Branch name must contain only numbers")
      .min(5, "Branch name must be at least 5 characters")
      .max(35, "Branch name must be less than 35 characters")
      .required("Branch name is required"),
    receiptaddr1: Yup.string()
      .max(35, "Receipt address 1 must be less than 35 characters")
      .matches(
        /^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]*$/,
        "Receipt address 1 must contain only English letters, numbers, spaces, and special characters"
      ),
    receiptaddr2: Yup.string()
      .max(35, "Receipt address 2 must be less than 35 characters")
      .matches(
        /^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]*$/,
        "Receipt address 2 must contain only English letters, numbers, spaces, and special characters"
      ),
    receiptaddr3: Yup.string()
      .max(55, "Receipt address 3 must be less than 55 characters")
      .matches(
        /^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]*$/,
        "Receipt address 3 must contain only English letters, numbers, spaces, and special characters"
      ),
    receiptaddr4: Yup.string()
      .matches(/^[0-9]+$/, "Receipt address 4 must contain only numbers")
      .min(5, "Branch name must be at least 5 characters")
      .max(5, "Receipt address 4 must be less than or equal to 5 characters")
      .required("Address 4 is required"),

    sapopttext1: Yup.string().when("officename", (officename, schema) => {
      return officename
        ? schema
            .matches(
              /^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]*$/,
              "Additional office name must contain only English letters, numbers, spaces, and special characters"
            )
            .max(35, "Additional office name must be less than 35 characters")
        : schema;
    }),
  });

  const formik = useFormik({
    initialValues: {
      customername: "",
      officename: "",
      sapopttext1: "",
      taxno: "",
      branchname: "",
      receiptaddr1: "",
      receiptaddr2: "",
      receiptaddr3: "",
      receiptaddr4: "",
      telno: "",
      iswthdraw: "1",
    },
    validationSchema: validationSchema,
    isInitialValid: false,
    onSubmit: (values) => {
      try {
        const _processtype =
          paymentReceiptData.transtype === "T01" ? "R01" : "R10";

        const payload = {
          pmlsid: pmlsid,
          receiptno: paymentReceiptData?.receiptno, // ส่ง receiptno ที่ได้จาก api /mapp/payment/receipt?
          remark: "", // ส่ง empty string
          status: "New", // ส่ง New (fix)
          receiptaddr1: values.receiptaddr1, // ส่ง ที่อยู่ 1
          receiptaddr2: values.receiptaddr2, // ส่ง ที่อยู่ 2
          receiptaddr3: values.receiptaddr3, // ส่ง ที่อยู่ 3
          receiptaddr4: values.receiptaddr4, // ส่ง ที่อยู่ 4
          customername: values?.iswthdraw === "1" ? null : values.customername, //#2099 hide ชื่อนามสกุลถ้าเลือกแบบเบิกได้ (ขอในนามบริษัท) ? null : ส่ง customername
          officename: values.officename, //#2041 ส่ง officename
          sapopttext1: values.sapopttext1 ? values.sapopttext1 : null, //#2280 เพิ่มส่ง sapopttext1
          telno: null, //#2041 เดิมส่ง เบอร์ติดต่อ -> จากที่ตัดออกจาก UI เปลี่ยนเป็นส่ง null
          taxno: values.taxno, // ส่ง เลขประจำตัวผู้เสียภาษี
          branchname: values.branchname, // ส่ง สาขา
          processtype: _processtype, //? #1515
          transtype: paymentReceiptData.transtype, //? #1909 - ขอใบกำกับภาษีเต็มรูป: เพิ่มส่ง transtype
          ticketno: paymentReceiptData?.ticketno, // ส่ง ticketno ที่ได้จาก api /mapp/payment/receipt?
          memberno: paymentReceiptData?.memberno || "", // ส่ง memberno ที่ได้จาก api /mapp/payment/receipt? ถ้าเป็น null ให้ส่ง empty string
          ispersonal: null, //#2041 null (fix)
          createby: 239, // #2281
          saptransdt: null, // ส่ง null
          memberprocessid: null, // ส่ง null (ขอติดไว้ก่อน)
          licenseplate: paymentReceiptData?.info1, // ส่ง info1 ที่ได้จาก api /mapp/payment/receipt?
          paytype: paymentReceiptData?.paytype, // ส่ง paytype ที่ได้จาก api /mapp/payment/receipt?
          amount: paymentReceiptData?.totalamount, // ส่ง totalamount ที่ได้จาก api /mapp/payment/receipt?
          iswthdraw: values?.iswthdraw === "1" ? 1 : 0,
          paysourcecode: paymentReceiptData?.paysourcecode //? https://dev.azure.com/mmats/AOT-CARPARK/_workitems/edit/2585/
        };

        createReceiptMutate.mutate(payload);
      } catch (err) {
        toastErr(String(err));
      }
    },
  });

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen w-full">
        <MoonLoader color="#4564c9" size={40} />
      </div>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="w-full relative h-screen flex flex-col gap-y-16  items-center justify-items-center">
        <div className="absolute right-4 top-5">
          <LanguagesDropdown />
        </div>
        <div className="flex items-center justify-center flex-col gap-5 py-20 w-full md:max-w-xl px-8 md:px-10">
          <div className="w-full flex flex-col items-center gap-2">
            <img
              src="/images/aot_logo.png"
              alt="aot-logo"
              className="w-full max-w-[150px] h-auto"
            />
            <span className="text-xs font-bold">
              {pmlsid &&
                i18n?.language &&
                getAirportNameFormPmlsAndLang(pmlsid, i18n?.language)}
            </span>
          </div>
          {step === STEP.DUE_TIME_OUT ? (
            <ResultWithContent
              status="ERROR"
              content={
                <div className="text-lg md:text-2xl font-bold text-blue-800">
                  <p>{t("unable to submit tax invoice")}</p>
                </div>
              }
            />
          ) : step === STEP.CANCEL_REQUEST ? (
            <ResultWithContent
              image="/images/status/error_receipt_status.png"
              content={
                <div className="text-lg md:text-2xl font-bold text-blue-800">
                  <p>{t("Receipt has been cancelled")}</p>
                </div>
              }
            />
          ) : step === STEP.ALREADY_REQUEST ? (
            <ResultWithContent
              status="SUCCESS"
              content={
                <div className="text-lg md:text-2xl font-bold text-blue-800">
                  <p>
                    {isDmkAirport
                      ? t("Already requested tax invoice dmk")
                      : t("Already requested tax invoice")}
                  </p>
                </div>
              }
            />
          ) : step === STEP.SUCCESS ? (
            <div className="w-full flex flex-col items-center ">
              <ResultWithContent
                status="SUCCESS"
                content={
                  <div className="text-lg md:text-2xl font-bold text-blue-800">
                    <p>{t("Save Request Tax Invoice")}</p>
                    <p>
                      {isDmkAirport
                        ? t("Request Tax Invoice contact info dmk")
                        : t("Request Tax Invoice contact info")}
                    </p>
                  </div>
                }
              />
              <ButtonAction
                onClick={() => {
                  navigate("https://carpark.airportthai.co.th/");
                }}
                sx={{ width: "60%" }}
              >
                {t("Go to Homepage")}
              </ButtonAction>
            </div>
          ) : step === STEP.FORM ? (
            <div className="w-full flex flex-col gap-5">
              <div className="flex items-center gap-2 mt-8 self-start">
                <UilListUl />
                <h1 className="text-xl font-semibold leading-none">
                  {t("Receipt Information")}
                </h1>
              </div>
              <hr className="w-full" />
              <InputTextFieldAOT
                label={t("Receipt No")}
                val={paymentReceiptData?.receiptno || ""}
                disabled
                fullWidth
              />
              <InputTextFieldAOT
                label={t("Payment Method")}
                val={paymentNameByPaymentCode || ""}
                disabled
                fullWidth
              />
              <InputTextFieldAOT
                label={t("Paid Amount")}
                val={
                  paymentReceiptData?.totalamount
                    ? `${Number(paymentReceiptData?.totalamount).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )} ${t("Baht")}`
                    : "" || ""
                }
                disabled
                fullWidth
              />
              {/* section input */}
              <div className="flex items-center gap-2 mt-6 self-start">
                <UilFileLandscapeAlt className="text-blue-800 flex-none" />
                <h1 className="text-xl font-semibold leading-none text-blue-800">
                  {t("Please enter the information to request a tax invoice")}
                </h1>
              </div>
              <p className="text-xl font-semibold  text-red-500">
                {isDmkAirport
                  ? t("Request Tax Invoice contact info2 dmk")
                  : t("Request Tax Invoic contact info2")}
              </p>
              <hr className="w-full" />
              <FormControl component="fieldset" className="self-start">
                <RadioGroup
                  row
                  aria-label="iswthdraw"
                  name="iswthdraw"
                  value={formik.values.iswthdraw}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setIsWithDrawerValue(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value={"1"}
                    control={<Radio />}
                    label={t("Reimbursable Receipt")}
                  />
                  <FormControlLabel
                    value={"0"}
                    control={<Radio />}
                    label={t("NonReimbursable Receipt")}
                  />
                </RadioGroup>
              </FormControl>
              <div className="w-full">
                <InputTextFieldAOT
                  type="text"
                  label={t("Company Name")}
                  name="officename"
                  val={formik.values.officename}
                  handleChange={formik.handleChange}
                  required={formik.values.iswthdraw === "1"}
                  fullWidth
                  maxLength={35}
                />
                <div className="h-2 p-0 m-0">
                  {formik.values.officename !== '' && formik.errors.officename && (
                    <span className="text-red-500 font-normal text-xs">
                      {formik.errors.officename}
                    </span>
                  )}
                </div>
              </div>
              <div className="w-full">
                <InputTextFieldAOT
                  type="text"
                  label={t("Additional Company Name")}
                  name="sapopttext1"
                  val={formik.values.sapopttext1}
                  handleChange={formik.handleChange}
                  fullWidth
                  maxLength={35}
                />
                <div className="h-2 p-0 m-0">
                  {formik.values.sapopttext1 !== "" &&
                    formik.errors.sapopttext1 && (
                      <span className="text-red-500 font-normal text-xs">
                        {formik.errors.sapopttext1}
                      </span>
                    )}
                </div>
              </div>
              {formik.values.iswthdraw != "1" && (
                <div className="w-full">
                  <InputTextFieldAOT
                    type="text"
                    label={t("Name Surname")}
                    name="customername"
                    val={formik.values.customername}
                    handleChange={formik.handleChange}
                    required={formik.values.iswthdraw !== "1"}
                    fullWidth
                    maxLength={35}
                  />
                  <div className="h-2 p-0 m-0">
                    {formik.errors.customername && (
                      <span className="text-red-500 font-normal text-xs">
                        {formik.errors.customername}
                      </span>
                    )}
                  </div>
                </div>
              )}
              <div className="w-full">
                <InputTextFieldAOT
                  label={t("Tax ID")}
                  name="taxno"
                  val={formik.values.taxno}
                  handleChange={formik.handleChange}
                  required
                  fullWidth
                  maxLength={13}
                />
                <div className="h-2 p-0 m-0">
                  {formik.errors.taxno && (
                    <span className="text-red-500 font-normal text-xs">
                      {formik.errors.taxno}
                    </span>
                  )}
                </div>
              </div>
              <div className="w-full">
                <InputTextFieldAOT
                  label={t("Branch")}
                  name="branchname"
                  val={formik.values.branchname}
                  handleChange={formik.handleChange}
                  required
                  fullWidth
                  placeholder={t("Enter 00000 for Head Office")}
                  maxLength={5}
                />
                <div className="h-2 p-0 m-0">
                  {formik.errors.branchname && (
                    <span className="text-red-500 font-normal text-xs">
                      {formik.errors.branchname}
                    </span>
                  )}
                </div>
              </div>
              <div className="w-full">
                <InputTextFieldAOT
                  label={t("Address One")}
                  placeholder={`${t("House No")} ${t("VillageBuilding")}`}
                  name="receiptaddr1"
                  val={formik.values.receiptaddr1}
                  handleChange={formik.handleChange}
                  required
                  fullWidth
                  maxLength={35}
                />
                <div className="h-2 p-0 m-0">
                  {formik.errors.receiptaddr1 && (
                    <span className="text-red-500 font-normal text-xs">
                      {formik.errors.receiptaddr1}
                    </span>
                  )}
                </div>
              </div>
              <div className="w-full">
                <InputTextFieldAOT
                  label={t("Address Two")}
                  placeholder={`${t("Road")} ${t("SubdistrictSubarea")}`}
                  name="receiptaddr2"
                  val={formik.values.receiptaddr2}
                  handleChange={formik.handleChange}
                  required
                  fullWidth
                  maxLength={35}
                />
                <div className="h-2 p-0 m-0">
                  {formik.errors.receiptaddr2 && (
                    <span className="text-red-500 font-normal text-xs">
                      {formik.errors.receiptaddr2}
                    </span>
                  )}
                </div>
              </div>
              <div className="w-full">
                <InputTextFieldAOT
                  label={t("Address Three")}
                  placeholder={`${t("DistrictArea")} ${t("Province")}`}
                  name="receiptaddr3"
                  val={formik.values.receiptaddr3}
                  handleChange={formik.handleChange}
                  required
                  fullWidth
                  maxLength={55}
                />
                <div className="h-2 p-0 m-0">
                  {formik.errors.receiptaddr3 && (
                    <span className="text-red-500 font-normal text-xs">
                      {formik.errors.receiptaddr3}
                    </span>
                  )}
                </div>
              </div>
              <div className="w-full">
                <InputTextFieldAOT
                  label={t("Address Four")}
                  placeholder={t("Postal Code")}
                  name="receiptaddr4"
                  val={formik.values.receiptaddr4}
                  handleChange={formik.handleChange}
                  required
                  fullWidth
                  maxLength={5}
                />
                <div className="h-2 p-0 m-0">
                  {formik.errors.receiptaddr4 && (
                    <span className="text-red-500 font-normal text-xs">
                      {formik.errors.receiptaddr4}
                    </span>
                  )}
                </div>
              </div>
              <ButtonAction
                sx={{
                  marginTop: 2,
                }}
                icon={<UilMessage className="text-white " />}
                type="submit"
                disabled={!formik.isValid}
              >
                {t("Submit")}
              </ButtonAction>
            </div>
          ) : null}
        </div>
      </div>
    </form>
  );
};

export default RequestReceiptPage;
