import { Button, styled, Typography } from "@mui/material";
import { Colors } from "../../constants/colors";

const getButtonStyle = (theme, size, variant) => {
  const baseStyles = {
    width: "100%",
    height: theme.spacing(size === "small" ? 5 : size === "medium" ? 7 : 9),
    borderRadius: theme.spacing(1.5),
    "&.Mui-disabled": {
      background: variant === "primary" ? Colors.disabledButton : "#E9F0F5", // Adjust the disabled background color
      border: "none",
      cursor: "not-allowed",
      pointerEvents: "auto",
    },
  };

  if (variant === "primary") {
    return {
      ...baseStyles,
      background: `transparent linear-gradient(90deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box`,
    };
  } else if (variant === "secondary") {
    return {
      ...baseStyles,
      background: "#E9F0F5",
    };
  } else if (variant === "orange") {
    return {
      ...baseStyles,
      background: `transparent linear-gradient(90deg, #ff975b 0%, #ff8844 100%) 0% 0% no-repeat padding-box`,
    };
  } else {
    return baseStyles;
  }
};

const ButtonStyled = styled(Button)(({ theme, size, variant }) =>
  getButtonStyle(theme, size, variant)
);

const TextAttach = styled(Typography)(({ theme, variant }) => ({
  font: "normal normal normal 18px/25px Noto Sans Thai",
  letterSpacing: "0px",
  color:
    variant === "primary"
      ? "#FFFFFF"
      : variant === "secondary"
      ? "#383838"
      : "#000000",
  [theme.breakpoints.down("lg")]: {
    font: "normal normal normal 16px/25px Noto Sans Thai",
  },
}));

const ButtonAction = ({
  onClick,
  disabled,
  children,
  icon,
  type = "button",
  size = "medium",
  variant = "primary",
  sx,
  textSx,
}) => {
  return (
    <ButtonStyled
      onClick={onClick}
      disabled={disabled}
      type={type}
      size={size}
      variant={variant}
      sx={{
        ...sx,
        gap: 2,
      }}
    >
      {icon && icon}
      <TextAttach sx={{ ...textSx }} variant={variant}>
        {children}
      </TextAttach>
    </ButtonStyled>
  );
};

export default ButtonAction;
