import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const CheckingDetail = ({
  process,
  onClickBackButton,
  findProcessStateNameByStateId,
}) => {
  const { t } = useTranslation();
  const { doctypeGroup } = useSelector((state) => state.masterData);

  const docTypeGroupName = useCallback(
    (doc) => {
      const { doctypeid } = doc;
      const find = doctypeGroup?.find(
        (item) =>
          item.doctypeid === doctypeid &&
          item.docgroupid === 1 &&
          item.isactive === 1
      );
      return find?.name;
    },
    [doctypeGroup]
  );

  return (
    <div className="w-full">
      <div className="w-full bg-[#245AB1] h-20 flex items-center justify-between px-8 mt-4 rounded-sm">
        <p className="text-white text-xl font-semibold">
          {process?.processname}
        </p>
        <div
          onClick={onClickBackButton}
          className="text-white cursor-pointer inline-flex gap-1 rounded-sm"
        >
          <ArrowBackIcon />
          {t("Back")}
        </div>
      </div>
      <div className="w-full h-12 bg-[#E9F0F5] flex items-center justify-end px-8 gap-7">
        <span className="font-semibold">{t("Request Status")}:</span>
        <span>{findProcessStateNameByStateId(process?.stateid)}</span>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="flex flex-col gap-2 py-6 px-9">
          <p className="font-semibold text-xl mb-3">
            {t("Request Information")}
          </p>
          <DetailItem title={t("Request No")} value={process?.processid} />
          <DetailItem title={t("Request Type")} value={process?.processname} />
          <DetailItem title={t("Member Package Information")} value={"-"} />
          <DetailItem title={t("License Plate")} value={"-"} />
          <DetailItem title={t("Transfer Amount")} value={"-"} />
          <DetailItem title={t("Submitted Date")} value={process?.createdt} />
          <DetailItem
            title={t("Document Attachment")}
            value={process?.doc?.length > 0 ? "มี" : "ไม่มี"}
          />
        </div>
        <div className="flex flex-col gap-2 py-6 px-9">
          <p className="font-semibold text-xl mb-3">
            {t("Attached Documents")}
          </p>
          <div className="flex flex-col gap-7">
            {process?.doc?.map((doc, index) => (
              <div key={doc.docid}>
                <div>
                  {index + 1}. {docTypeGroupName(doc)}
                </div>
                <span className="mt-8 mx-4 text-slate-400">{doc.docname}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const DetailItem = ({ title, value }) => {
  return (
    <div className="inline-flex px-2">
      <span className="block w-[180px] text-lg font-medium">{title}:</span>
      <span className="text-[#898A8F]">{value}</span>
    </div>
  );
};

export default CheckingDetail;
