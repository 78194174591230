import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { formattedPrice } from "../../utils/format";
import dayjs from "dayjs";

const BoxOuter = styled(Box)(({ theme }) => ({
  boxShadow: "0px 3px 6px #00000029",
  border: "1px solid #707070",
  borderRadius: "13px",
  width: "100%",
  display: "flex",
  flexDirection: "column",

}));

const BoxHeader = styled(Box)(({ theme }) => ({
  paddingTop: "2rem",
  marginLeft: "2rem",
  marginRight: "2rem",
  display: "flex",
  borderBottom: "1px solid #2F2E2F",

  [theme.breakpoints.down("lg")]: {
    marginLeft: "2rem",
    marginRight: "2rem",
  },
}));

const HeaderText = styled(Typography)(({ theme }) => ({
  font: "normal normal 500 30px/60px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  [theme.breakpoints.down("lg")]: {
    //marginLeft: '2rem',
    //marginRight: '2rem'
  },
}));

const BoxTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  paddingTop: "2rem",
  marginLeft: "2rem",
  marginRight: "2rem",
  [theme.breakpoints.down("lg")]: {
    //marginLeft: '2rem',
    //marginRight: '2rem'
  },
}));

const TitleText = styled(Typography)(({ theme }) => ({
  font: "normal normal 500 20px/60px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  paddingLeft: "1.5rem",
  [theme.breakpoints.down("lg")]: {
    //marginLeft: '2rem',
    //marginRight: '2rem'
  },
}));

const BoxDetail = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  paddingTop: "2rem",
  marginLeft: "3rem",
  marginRight: "3rem",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
}));

const BoldText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 20px/36px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  //paddingLeft: '2rem',
  marginTop: "1rem",
  marginBottom: "1.2rem",
  [theme.breakpoints.down("lg")]: {
    //marginLeft: '2rem',
    //marginRight: '2rem'
  },
}));

const NormalText = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 18px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  //paddingLeft: '2rem',
  [theme.breakpoints.down("lg")]: {
    marginBottom: "1rem",
  },
}));

const BoxSubject = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  [theme.breakpoints.down("lg")]: {
    //marginLeft: '2rem',
    //marginRight: '2rem'
  },
}));

const RedText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 20px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#FC3838",
  //paddingLeft: '2rem',
  [theme.breakpoints.down("lg")]: {
    marginBottom: "1rem",
  },
}));
const BoxSummary = styled(Box)(({ theme }) => ({
  paddingTop: "2rem",
  marginLeft: "2rem",
  marginRight: "2rem",
  marginBottom: "0.5rem",
  display: "flex",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "2rem",
    marginRight: "2rem",
    flexDirection: "column",
  },
}));

const BoxTotalLeft = styled(Box)(({ theme }) => ({
  width: "50%",
  background: "#E9F0F5 0% 0% no-repeat padding-box",
  height: "7rem",
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "flex-end",
  paddingBottom: "1rem",
  paddingRight: "1rem",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "0",
    paddingRight: "0",
  },
}));

const TotalLeftText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 25px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  paddingLeft: "2rem",
  [theme.breakpoints.down("lg")]: {
    //marginBottom: '1rem'
  },
}));

const BoxTotalRight = styled(Box)(({ theme }) => ({
  width: "50%",
  background: "#245AB1 0% 0% no-repeat padding-box",
  height: "7rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const TotalRightText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 50px/85px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#FFFFFF",
  paddingLeft: "2rem",
  [theme.breakpoints.down("lg")]: {
  },
}));

const BoxCalParking = ({ data, airportText, parkingZoneText }) => {
  const { t } = useTranslation();
  return (
    <BoxOuter>
      <BoxHeader>
        <HeaderText>{t("Calculate a Parking Fee")}</HeaderText>
      </BoxHeader>
      <BoxTitle>
        <img
          src="/images/calparking/ico_linegraycar.svg"
          alt=""
          style={{ height: "58px", width: "58px" }}
        />
        <TitleText>{t('Parking Fee Detail')}</TitleText>
      </BoxTitle>
      <BoxDetail>
        <BoxSubject>
          <img
            src="/images/calparking/ico_place.svg"
            alt=""
            style={{ height: "58px", width: "58px" }}
          />
          <BoldText>{t('Parking Location')}</BoldText>
          <NormalText>{airportText}</NormalText>
          {parkingZoneText && (
            <>
              <NormalText>{parkingZoneText}</NormalText>
            </>
          )}
        </BoxSubject>
        <BoxSubject>
          <img
            src="/images/calparking/ico_carentre.svg"
            alt=""
            style={{ height: "58px", width: "58px" }}
          />
          <BoldText>{t("Entry Date")}</BoldText>
          <NormalText>
            {(data?.start && dayjs(data?.start).format("DD/MM/YYYY HH:mm")) ||
              ""}
          </NormalText>
        </BoxSubject>
        <BoxSubject>
          <img
            src="/images/calparking/ico_carout.svg"
            alt=""
            style={{ height: "58px", width: "58px" }}
          />
          <BoldText>{t("Exit Date")}</BoldText>
          <NormalText>
            {(data?.end &&
              dayjs(data?.end).format("DD/MM/YYYY HH:mm")) ||
              ""}
          </NormalText>
        </BoxSubject>
        <BoxSubject>
          <img
            src="/images/calparking/ico_total.svg"
            alt=""
            style={{ height: "58px", width: "58px" }}
          />
          <BoldText>{t('Total Parking Time')}</BoldText>
          <RedText>{`${
            data?.durationday ? data.durationday + ` ${t('Day')}` : ""
          } ${
            data?.durationhour ? data.durationhour + ` ${t('Hour')}` : ""
          } ${
            data?.durationminute ? data.durationminute + ` ${t('Minute')}` : ""
          }`}</RedText>
        </BoxSubject>
      </BoxDetail>
      <BoxSummary>
        <BoxTotalLeft>
          <TotalLeftText>{t('Total Parking Fee')}</TotalLeftText>
        </BoxTotalLeft>
        <BoxTotalRight>
          {/* <TotalMoneyText>THB</TotalMoneyText> */}
          <TotalRightText>{formattedPrice(data?.fee)}</TotalRightText>
        </BoxTotalRight>
      </BoxSummary>
    </BoxOuter>
  );
};

export default BoxCalParking;
